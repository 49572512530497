import React from 'react'

let name = "Charan"

function App() {
  return (
    <div>
      <h1>My name is {name}</h1>
      <img src="assets/images/isha.jpg" width="400px" alt="isha"/>
    </div>
  )
}

export default App